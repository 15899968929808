<template>
    <div>
        <el-container>
            <el-header style="padding: 0" height="100%">
                <vheader class="hidden-sm-and-down"></vheader>
                <ivheadera class="hidden-md-and-up"></ivheadera>
            </el-header>
            <el-main style="padding: 0">
              <div class="w">
                <div class="Q1-container zh">
                  <div class="funA-top">
                    <div class="content">
                      <div class="title">
                        <img src="../../assets/images/Q/Q6/header-title.jpg" alt="">
                      </div>
                      <div class="list hidden-sm-and-down">
                        <ul>
                          <li @click="btn1">趣活动主页</li>
                          <li @click="btn2">活动</li>
                          <li @click="btn3">主办方</li>
                        </ul>
                        <el-popover
                          placement="bottom"
                          width="200"
                          trigger="hover"
                          content="目前仅限通过主办方认证的用户发起活动">
                          <div class="btn" slot="reference" @click="btn4">
                            <i class="el-icon-s-flag"></i>
                            <span>免费发起活动</span>
                          </div>
                        </el-popover>
<!--                        <el-popover-->
<!--                          placement="bottom"-->
<!--                          title="标题"-->
<!--                          width="200"-->
<!--                          trigger="hover"-->
<!--                          content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">-->
<!--                          <el-button slot="reference" class="btn">-->
<!--                            <i class="el-icon-s-flag"></i>-->
<!--                            <span>免费发起活动</span>-->
<!--                          </el-button>-->
<!--                        </el-popover>-->
                      </div>
                    </div>
                    <div class="activity-cur">
                      <el-carousel  indicator-position="outside" trigger="hover" :height="activityCurH+'px'">
                        <el-carousel-item v-for="(item,index) in carouselData" :key="index">
                          <h3 class="small">
                            <img ref="activityCur" @click="curBtn(item.URL)"  @load="activityLoad" width="100%" :src="item.IMAGE" alt="">
                          </h3>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="phone-list-btn hidden-md-and-up">
                      <button @click="btn1">趣活动主页</button>
                      <button @click="btn2">活动</button>
                      <button @click="btn3">主办方</button>
                      <button @click="btn4phone">
                        <i class="el-icon-s-flag"></i>
                        <span>免费发起活动</span>
                      </button>
                    </div>
                  </div>
                  <div class="act-recommend">
                    <div class="top-title">
                      <img src="../../assets/images/Q/Q1/recommend.png" alt="">
                      <span class="hidden-sm-and-down" @click="lookAllact">查看全部<i class="el-icon-arrow-right"></i></span>
                    </div>
                    <div class="funActive-card">
                      <el-row>
                        <el-carousel indicator-position="outside" @change="changeListener"  trigger="hover" :height="funCurHeight+1+'px'">
                          <el-carousel-item v-for="(arr, index) in recActivity" :key="index">
                            <h3 class="small" ref="funCur">
                              <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in arr" :key="index1">
                                <div class="fcard-item" @click="activityBtn(item)">
                                  <el-col :xs="12" :sm="24" :md="24">
                                    <div class="over-hidden">
                                      <img class="item-img" @load="actLoad" width="100%" :src="item.ONE" alt="">
                                    </div>
                                  </el-col>
                                  <el-col :xs="12" :sm="24" :md="24">
                                    <div class="fcard-content">
                                      <div class="fcard-txt">
                                        <p>{{item.TITLE}}</p>
                                      </div>
                                      <div class="fcard-icon">
                                        <p><i class="el-icon-time"></i><span>{{getTime(item.STARTTIME)}}</span>-<span>{{getTime(item.ENDTIME)}}</span></p>
                                        <p :style="{visibility: item.ADDRESS?'unset':'hidden'}"><i class="el-icon-location"></i>{{item.ADDRESS}}</p>
                                      </div>
                                      <div class="fcard-foot clearfloat">
                                        <img :src="item.images" alt="">
                                        <span class="display-none">{{item.CNNAME == '' ? item.ENNAME : item.CNNAME }}</span>
                                      </div>
                                    </div>
                                  </el-col>
                                </div>
                              </el-col>
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
                            </h3>
                          </el-carousel-item>
<!--                          <el-carousel-item>-->
<!--                            <h3 class="small">-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                            </h3>-->
<!--                          </el-carousel-item>-->
<!--                          <el-carousel-item>-->
<!--                            <h3 class="small">-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                              <el-col :xs="24" :sm="8" :md="8">-->
<!--                                <div class="fcard-item">-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="over-hidden">-->
<!--                                      <img class="item-img" width="100%" src="../../assets/images/HOME/fun-card01.png" alt="">-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                  <el-col :xs="12" :sm="24" :md="24">-->
<!--                                    <div class="fcard-content">-->
<!--                                      <div class="fcard-txt">-->
<!--                                        <p>SPORTS Y FUN</p>-->
<!--                                        <p>阳光有氧,双人瑜伽之旅</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-icon">-->
<!--                                        <p><i class="el-icon-time"></i>03月01日 09:00</p>-->
<!--                                        <p><i class="el-icon-location"></i>深圳市市民中心</p>-->
<!--                                      </div>-->
<!--                                      <div class="fcard-foot clearfloat ">-->
<!--                                        <img src="../../assets/images/HOME/cityplus+.png" alt="">-->
<!--                                        <span class="display-none">CITYPLUS 城市+</span>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </el-col>-->
<!--                                </div>-->
<!--                              </el-col>-->
<!--                            </h3>-->
<!--                          </el-carousel-item>-->
                        </el-carousel>
                      </el-row>
                    </div>
                    <div class="phone-all-btn hidden-md-and-up">
                      <button @click="lookAllact">查看全部</button>
                    </div>
                  </div>
                  <div class="hot-sponsor">
                    <div class="top-title">
                      <img src="../../assets/images/Q/Q1/sponsor.png" alt="">
                      <span class="hidden-sm-and-down" @click="lookAllspon">查看全部<i class="el-icon-arrow-right"></i></span>
                    </div>
                    <div class="sponsor-cur">
                      <el-carousel  indicator-position="outside" trigger="hover" @change="changeListener2" :height="sponsorCurH+26+'px'">
                        <el-carousel-item v-for="(arr,index) in sponsorData" :key="index">
                          <h3 class="small" ref="sponsorHeight">
                            <el-row type="flex" style="width: 100%">
                              <el-col :xs="24" :sm="24" :md="6" v-for="(item,index1) in arr" :key="index1" class="offset-top">
                                <div class="sponsor-card"  @click="sponsorBtn(item.SPONSOR_ID)">
                                  <el-col :xs="10" :sm="10" :md="24">
                                    <div class="logo">
                                      <img @load="sponLoad" :src="item.images" alt="">
                                    </div>
                                    <div class="sponsor-name">
                                      <span class="name">{{item.CNNAME == '' ? item.ENNAME : item.CNNAME }}</span>
                                      <span class="vip-ico"><img src="../../assets/images/Q/Q1/vip-icon.png" alt=""></span>
                                    </div>
                                    <div class="follow-btn">
                                      <button @click.stop="changFollow(item.gz, item.SPONSOR_ID)">{{item.gz === 1?'已关注':'关注'}}</button>
                                    </div>
                                  </el-col>
                                  <el-col :xs="14" :sm="14" :md="24">
                                    <div class="txt">
                                      {{item.SHORTCONTENT}}
                                    </div>
                                  </el-col>
                                </div>
                              </el-col>
                            </el-row>
                          </h3>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                    <div class="phone-all-btn hidden-md-and-up">
                      <button @click="lookAllspon">查看全部</button>
                    </div>
                  </div>
                </div>
              </div>
            </el-main>
            <el-footer>
                <vfooter class="hidden-sm-and-down"></vfooter>
                <ivfooter class="hidden-md-and-up"></ivfooter>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import { q1Carousel, q1Activity, collectSponsor, noCollectSponsor,
  delCollect, addCollect, wiccoActivity } from '../../api'
import qs from 'qs'
export default {
  data () {
    return {
      carouselData: [],
      activityCurH: '',
      funCurHeight: '',
      sponsorCurH: '',
      sponsorData: [
      //   {
      //   image: require('../../assets/images/Q/Q1/logo.png'),
      //   name: 'ciytplus 城市+',
      //   txt: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。',
      //   image1: require('../../assets/images/Q/Q1/logo.png'),
      //   name1: 'ciytplus 城市+',
      //   txt1: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。',
      //   image2: require('../../assets/images/Q/Q1/logo.png'),
      //   name2: 'ciytplus 城市+',
      //   txt2: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。',
      //   image3: require('../../assets/images/Q/Q1/logo.png'),
      //   name3: 'ciytplus 城市+',
      //   txt3: '平台以服务城市国际化建设与对外交流合作为中心工作，实践“官方指导、民间运营”的新模式，围绕“国际化城市建设互联网新形态”的核心概念。'
      // }
      ],
      recActivity: []
    }
  },
  inject: ['reload'],
  methods: {
    curBtn (i) {
      window.open(i, '_blank')
    },
    changeListener (index) {
      this.funCurHeight = this.$refs.funCur[index].clientHeight
    },
    changeListener2 (index) {
      this.sponsorCurH = this.$refs.sponsorHeight[index].clientHeight
    },
    changFollow (e, id) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        let data = {
          USERID: userInfo.userId,
          SPONSORID: id
        }
        if (e === 1) {
          delCollect(qs.stringify(data)).then((res) => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        } else {
          addCollect(qs.stringify(data)).then((res) => {
            if (res.data.result === 'ok') {
              this.reload()
            }
          })
        }
      }
    },
    btn1 () {
      this.$router.push('/Activities')
    },
    btn2 () {
      this.$router.push('/activitylist')
    },
    btn3 () {
      this.$router.push('/sponsorlist')
    },
    btn4 () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo.userName == 'yk'){
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        this.$router.push('/create')
      }
    },
    btn4phone () {
      this.$message({
        type: 'info',
        message: '请到PC端发起活动。',
        center: true
      })
    },
    activityBtn (item) {
      if(item.TYPE == 'wicco'){
        window.open(`http://www.wicco.net/detail/${item.ID}`)
      } else {
        this.$router.push({
          name: 'cityQ5',
          query: {
            id: item.MAKEACTIVITY_ID,
            uid: item.USERID
          }
        })
      }
    },
    sponsorBtn (id) {
      this.$router.push({
        name: 'cityQ4',
        query: {
          id: id
        }
      })
    },
    lookAllact () {
      this.$router.push('/activitylist')
    },
    lookAllspon () {
      this.$router.push('/sponsorlist')
    },
    getTime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      // var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var currentTime = month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    activityLoad () {
      this.$nextTick(() => {
        this.activityCurH = this.$refs.activityCur[0].height
      })
    },
    actLoad () {
      this.$nextTick(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
      })
    },
    sponLoad () {
      this.$nextTick(() => {
        this.sponsorCurH = this.$refs.sponsorHeight[0].clientHeight
      })
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.activityCurH = this.$refs.activityCur[0].height
      this.activityLoad()
      this.funCurHeight = this.$refs.funCur[0].clientHeight
      this.sponsorCurH = this.$refs.sponsorHeight[0].clientHeight
    })
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    q1Carousel().then((res) => {
      this.carouselData = res.data
    })
    // q1Activity().then((res) => {
    //   let result = []
    //   let result1 = []
    //   for (let i = 0; i < res.data.length; i++) {
    //     if (res.data[i].RECOMMEND == 'true') {
    //       if (res.data[i].是否显示 == 'true') {
    //         result.push(res.data[i])
    //       }
    //     }
    //   }
    //   for (let i = 0; i < result.length; i += 3) {
    //     result1.push(result.slice(i, i + 3))
    //   }
    //   this.recActivity = result1
    // })
    let p1 = q1Activity()
    let p2 = wiccoActivity()
    Promise.all([p1,p2]).then(([cpData,wiccoData]) => {
      let cpActivity = cpData.data.filter((item)=>{
        return item.RECOMMEND == 'true' && item.是否显示 == 'true'
      })
      let wiccoEvent = wiccoData.data.data.map(item => {
        return {
          ONE: item.img_url,
          TITLE: item.name,
          STARTTIME: item.start_time,
          ENDTIME: item.end_time,
          ADDRESS: item.address,
          CNNAME: item.area_name,
          images: item.org_logo,
          TYPE: 'wicco',
          ID: item.id
        }
      }).slice(0,3)
      // let result = [...cpActivity, ...wiccoEvent].sort((a,b) => {
      //   return Date.parse(b.STARTTIME) - Date.parse(a.STATEDATE)
      // })
      let length = Math.max(cpActivity.length,wiccoEvent.length)
      let result = []
      for (let i = 0; i < length; i += 3) {
        if(i < cpActivity.length) result.push(cpActivity.slice(i, i + 3))
        if(i < wiccoEvent.length) result.push(wiccoEvent.slice(i, i + 3))
      }
      this.recActivity = result
    })
    let result = []
    collectSponsor(userInfo.userId).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].ISRZ === 'true') {
          result.push(res.data[i])
        }
      }
      noCollectSponsor(userInfo.userId).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].ISRZ === 'true') {
            result.push(res.data[i])
          }
        }
        for (let i = 0; i < result.length; i += 4) {
          this.sponsorData.push(result.slice(i, i + 4))
        }
        // console.log(this.sponsorData)
      })
    })
    this.$nextTick(() => {
      setTimeout(() => {
        this.funCurHeight = this.$refs.funCur[0].clientHeight
        this.sponsorCurH = this.$refs.sponsorHeight[0].clientHeight
      }, 200)
    })
  }
}
</script>

<style lang="less" scoped src="../../assets/css/CityQ1.less">
</style>
<style lang="less">
  .Q1-container{
    &.zh{
      .act-recommend{
        .top-title{
          img{
            width: 20%;
          }
          @media screen and (max-width: 760px){
            img{
              width: 45%;
            }
          }
        }
      }
      .hot-sponsor{
        .top-title{
          img{
            width: 20%;
          }
          @media screen and (max-width: 760px){
            img{
              width: 45%;
            }
          }
        }
      }
    }
    .el-row--flex{
      flex-wrap: wrap;
    }
    .el-carousel__button{
      background-color: #ABABAB;
      opacity: 1;
      /*cursor: pointer;*/
    }
    .el-carousel__indicator.is-active button{
      background-color: #00B6CD;
    }
    .funA-top{
      .content{
        .list{
          .btn{
            display: inline-block;
            padding: 0.15rem 0.5rem;
            background: #C1DB5C;
            cursor: pointer;
            span{
              font-size: 0.25rem;
              color: #434A3A;
            }
          }
        }
      }
    }
  }
@media screen and (max-width:990px){
  .Q1-container .zh{
    .act-recommend{
      .top-title{
        img{
          width: 32% !important;
        }
      }
    }
    .hot-sponsor{
      .top-title{
        img{
          width: 32% !important;
        }
      }
    }
  }
}
</style>
